import { render, staticRenderFns } from "./KeywordManage.vue?vue&type=template&id=48867d28&scoped=true&"
import script from "./KeywordManage.vue?vue&type=script&lang=js&"
export * from "./KeywordManage.vue?vue&type=script&lang=js&"
import style0 from "./KeywordManage.vue?vue&type=style&index=0&id=48867d28&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48867d28",
  null
  
)

export default component.exports